import { getToken } from '@/utils/cookie'
import request from '@/utils/request'
import '@/utils/types.js'

/**
 * @returns {RequestLifeCycle}
 */
export function getRequestLifecycle() {
  return request({
    url: `/api/requests/lifecycle/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {{
 * state?: RequestStateMethods,
 * "me.author"?: boolean,
 * "me.assignee"?: boolean,
 * "store.id"?: number,
 * "route.code"?: number,
 * "created.start"?: string,
 * "created.end"?: string
 * limit?: number,
 * offset?: number
 * }} params
 * @returns {{requests: RequestShort}}
 */
export function getRequests(params) {
  return request({
    url: `/api/requests/`,
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {RequestCreate} data
 * @returns {RequestFull}
 */
export function createRequest(data) {
  return request({
    url: `/api/requests/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {number} requestId
 * @returns {RequestFull}
 */
export function getRequest(requestId) {
  return request({
    url: `/api/requests/${requestId}/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {number} requestId
 * @param {{
 * gi: number, installation: Installation,
 * dismantling: Installation, comments?: string
 * }} data
 * @returns {RequestFull}
 */
export function updateRequest(requestId, data) {
  return request({
    url: `/api/requests/${requestId}/`,
    method: 'patch',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {{requestId: number, method: RequestStateMethods}} params
 * @param {{comment?: string}} data
 * @returns {RequestFull}
 */
export function setRequestState({ requestId, method }, data) {
  return request({
    url: `/api/requests/${requestId}/state/${method}/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {number} requestId
 * @param {{role: UserRoles}} data
 * @returns {RequestFull}
 */
export function approveRequest(requestId, data) {
  return request({
    url: `/api/requests/${requestId}/approval/approve/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
/**
 * @param {number} requestId
 * @param {{role: UserRoles}} data
 * @returns {RequestFull}
 */
export function revokeRequest(requestId, data) {
  return request({
    url: `/api/requests/${requestId}/approval/revoke/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

/**
 * Поиск задач
 * @param {number} requestId
 * @returns {{tasks: RequestTaskShort[]}}
 */
export function getRequestTasks(requestId) {
  return request({
    url: `/api/requests/${requestId}/tasks/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

/**
 * Создать задачу
 * @param {number} requestId
 * @param {{assignee: number, text: string}} data
 * @returns {RequestTask}
 */
export function createRequestTasks(requestId, data) {
  return request({
    url: `/api/requests/${requestId}/tasks/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

/**
 * Детальная информация по задаче
 * @param {{requestId: number, taskId: number}} params
 * @returns {RequestTask}
 */
export function getRequestTask({ requestId, taskId }) {
  return request({
    url: `/api/requests/${requestId}/tasks/${taskId}/`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

/**
 * Завершить/отменить задачу
 * @param {{requestId: number, taskId: number, type: "cancel" | "complete"}} params
 * @param {{comment: string}} data
 * @returns {RequestTask}
 */
export function changeRequestTask({ requestId, taskId }, data) {
  return request({
    url: `/api/requests/${requestId}/tasks/${taskId}/complete/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

/**
 * Добавить/удалить макет к/из заявки
 * @param {{requestId: number, type: "append" | "remove"}} params
 * @param {{layout: number}} data
 * @returns {LayoutShort | undefined}
 */
export function changeRequestLayout({ requestId, type }, data) {
  return request({
    url: `/api/requests/${requestId}/layouts/${type}/`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
