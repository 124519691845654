import { createStore } from 'vuex'
import auth from './auth'
import banners from './banners'
import dataset from './dataset'
import requests from './requests'

export default createStore({
  state: {
    error: null,
    success: null,
    isLoading: false,
  },
  mutations: {
    setStateProps(state, payload) {
      state[payload.prop] = payload.value
    },
  },
  actions: {
    showError({ state }, error) {
      state.error = error
    },
    hideError({ state }) {
      state.error = null
    },
    /**
     * @param {{text: string, btnText: string, onClick?: function}} payload
     */
    showSuccess({ state }, payload) {
      state.success = payload
    },
    hideSuccess({ state }) {
      state.success = null
    },
  },
  modules: {
    auth,
    banners,
    dataset,
    requests,
  },
})
